if (document.readyState !== "loading") {
  accordionHandler();
} else {
  document.addEventListener("DOMContentLoaded", accordionHandler);
}

function accordionHandler() {
  // resize event
  const handleResize = debounce(() => {
    const expandedAccordions = document.querySelectorAll(".expanded");

    expandedAccordions.forEach((accordion) => {
      accordion.style.maxHeight = accordion.scrollHeight + "px";
    });
  }, 1000);
  window.addEventListener("resize", handleResize);
  // end resize event

  // click event
  const triggers = document.querySelectorAll(".accordion-trigger");
  if (triggers && triggers.length) {
    triggers.forEach(function (trigger) {
      trigger.addEventListener("click", function () {
        const parentNode = this.parentNode;
        let accordionContainer = parentNode.querySelector(
          ".accordion-container"
        );

        if (!accordionContainer) {
          accordionContainer = parentNode.parentNode.querySelector(
            ".accordion-container"
          );
        }

        const accordionIcon = trigger.querySelector(".accordion-icon");

        if (accordionContainer) {
          if (accordionContainer.classList.contains("expanded")) {
            accordionContainer.classList.remove("expanded");
            accordionContainer.style.maxHeight = "";
            trigger.classList.remove("expanded");
          } else {
            accordionContainer.classList.add("expanded");
            accordionContainer.style.maxHeight =
              accordionContainer.scrollHeight + "px";
            trigger.classList.add("expanded");
          }
        }

        if (accordionIcon) {
          if (accordionIcon.classList.contains("expanded")) {
            accordionIcon.classList.remove("expanded");
            trigger.classList.remove("expanded");
          } else {
            accordionIcon.classList.add("expanded");
            accordionIcon.style.maxHeight = trigger.classList.add("expanded");
          }
        }
      });
    });

    // end resize event
    // open first accordion
    const firstAccordion = triggers[0].parentNode.querySelector(
      ".accordion-container"
    );
    const firstAccordionIcon = triggers[0].querySelector(".accordion-icon");
    if (firstAccordion && firstAccordionIcon) {
      firstAccordionIcon.classList.add("expanded");
      firstAccordion.classList.add("expanded");
      firstAccordion.style.maxHeight = firstAccordion.scrollHeight + "px";
    }
    // end open first accordion
  }
}
