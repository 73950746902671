function downloadImage(e) {
  const imageUrl = e.target.getAttribute("data");
  const filename = imageUrl.split("/").pop();

  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}
