function initCounterObserver() {
  const stats = document.querySelectorAll(".stat-number-animation");
  const ghostStats = document.querySelectorAll(".ghost-stat-number");

  if (ghostStats)
    ghostStats.forEach((ghostStat) => {
      const ghostLength = ghostStat.innerHTML.replace(/\d/g, "X")
      ghostStat.innerHTML = ghostLength
    });

  if (stats) {
    let statsObserver = new IntersectionObserver(function (entries) {
      entries.forEach((entry) => {
        function countStats() {
          let length = entry.target.innerHTML.length;

          const statNumber = parseFloat(entry.target.innerHTML);

          let counter = setInterval(updated, 10);

          const timeValue = (statNumber / 100) * 1.5;
          let count = (statNumber / 100) * 25;

          function updated() {
            if (count + timeValue < statNumber) {
              count = count + timeValue;
              let displayCount = String(count).substring(0, length);
              entry.target.innerHTML = Number(displayCount).toLocaleString("en-US");

              const num = 100000
            }

            if (count + timeValue >= statNumber) {
              entry.target.innerHTML = statNumber.toLocaleString("en-US");
              count = statNumber;
              clearInterval(counter);
            }
          }
        }

        if (
          entry.isIntersecting &&
          !entry.target.classList.contains("intersected")
        ) {
          entry.target.classList.add("intersected");
          countStats();
        }
      });
    });

    stats.forEach((stat) => {
      statsObserver.observe(stat);
    });
  }
}

if (document.readyState !== "loading") {
  initCounterObserver();
} else {
  document.addEventListener("DOMContentLoaded", initCounterObserver);
}
