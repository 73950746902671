function hideShowOverlay() {
  const videoCollection = document.getElementsByClassName("video__content");

  if (videoCollection) {
    const videoArray = Array.from(videoCollection);

    videoArray.forEach((video, i) => {
      video.addEventListener("click", () => {
        video.controls = true;
        if (navigator.userAgent.indexOf("Firefox") > 0) {
          video.play();
        }
        if (!navigator.userAgent.indexOf("Firefox") > 0) {
          video.muted = false;
          video.paused = false;
          video.autoplay = true;
        }
        video.parentNode.nextElementSibling.style.opacity = 0;
        video.parentNode.nextElementSibling.nextElementSibling.style.opacity = 0;
      });
      video.addEventListener("ended", () => {
        video.currentTime = 0;
        video.controls = false;
        video.paused = true;
        video.muted = true;
        video.parentNode.nextElementSibling.style.opacity = 1;
        video.parentNode.nextElementSibling.nextElementSibling.style.opacity = 1;
      });
    });
  }
}

if (document.readyState !== "loading") {
  hideShowOverlay();
} else {
  document.addEventListener("DOMContentLoaded", hideShowOverlay);
}
