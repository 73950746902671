// parallax scroll effect

let parallaxAssetObserver = new IntersectionObserver(
  function (entries) {
    entries.forEach(function (entry) {
      function removeEvent() {
        document.removeEventListener("scroll", parallaxEffect);
      }
      
      
      function parallaxEffect() {
        if (entry.target.classList.contains("in-view")) {
          let scrollDistance = entry.target.getBoundingClientRect().y;
          let parallaxOffset = scrollDistance / 20;
          entry.target.style.transform = `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, ${parallaxOffset}, 0, 1)`;
        } else {
          removeEvent();
        }
      }
      
      if (entry.isIntersecting) {
        if (!entry.target.classList.contains("in-view")) {
          entry.target.classList.add("in-view");
          document.addEventListener("scroll", parallaxEffect);
          parallaxEffect();
        }
      } else {
        if (entry.target.classList.contains("in-view")) {
          entry.target.classList.remove("in-view");
        }
      }
    });
  },
  { root: null }
  );
  
  function initializeParallax() {
    const parallaxAssets = document.querySelectorAll(".parallax-effect");
    parallaxAssets.forEach((parallaxAsset) => {
      parallaxAssetObserver.observe(parallaxAsset);
    });
  }
  
  if (document.readyState !== "loading") {
    initializeParallax();
  } else {
    document.addEventListener("DOMContentLoaded", initializeParallax);
  }
