if (document.readyState !== "loading") {
  initMobileMenuHandler();
} else {
  document.addEventListener("DOMContentLoaded", initMobileMenuHandler);
}

const body = document.body;
let scrollPosition = 0;

function disableScroll() {
  scrollPosition = window.scrollY;
  body.style.position = "fixed";
  body.style.top = `-${scrollPosition + 20}px`;
  body.style.width = "100%";
}

function enableScroll() {
  body.style.removeProperty("position");
  body.style.removeProperty("top");
  body.style.removeProperty("width");

  if (scrollPosition > 0) {
    window.scrollTo(0, scrollPosition);
  }
}

function initMobileMenuHandler() {
  const mobileMenuHandler = document.querySelector("#mobile-menu-trigger");

  const { body } = document;
  const mobileMenu = document.querySelector(".mobile-menu__outer");

  mobileMenuHandler.addEventListener("click", function (e) {
    const menuActive = mobileMenu.classList.contains("active");

    if (menuActive) {
      mobileMenu.classList.remove("active");
      body.classList.remove("mobile-menu-active");
      mobileMenuHandler.classList.remove("active");
      enableScroll();
      return;
    }

    mobileMenu.classList.add("active");
    body.classList.add("mobile-menu-active");
    mobileMenuHandler.classList.add("active");
    disableScroll();
  });

  const mobileWorkflowButtons = document.querySelectorAll(
    ".mobile-menu__outer .site-header__menu-button"
  );

  if (mobileWorkflowButtons && mobileWorkflowButtons.length) {
    const menusWrapper = document.querySelector(".mobile-menu__menus-wrapper");

    mobileWorkflowButtons.forEach((button) => {
      button.addEventListener("click", function (e) {
        const workflow = button.dataset.workflow;
        const opposite = {
          "travel-info": "enquiries",
          enquiries: "travel-info",
        };

        menusWrapper.classList.remove(opposite[workflow]);
        menusWrapper.classList.add(workflow);
        document.body.classList.add("mobile-workflow-active");
      });
    });
  }
}
