function handleTable() {
  const tables = document.querySelectorAll(".table__wrapper");

  if (tables.length) {
    tables.forEach((table) => {
      const handleHeadHeight = () => {
        const tableSubHeaders = table.querySelectorAll(".table__sub-head-cell");

        const rowHeights = {}; // Store the maximum height for each cellRow

        tableSubHeaders.forEach((cell) => {
          const cellRow = cell.dataset.cellRow;
          const cellContent = cell.querySelector(".table__sub-head-content");

          if (
            !rowHeights[cellRow] ||
            cellContent.scrollHeight > rowHeights[cellRow]
          ) {
            rowHeights[cellRow] = cellContent.scrollHeight;
          }
        });

        tableSubHeaders.forEach((cell) => {
          const cellRow = cell.dataset.cellRow;
          cell.style.height = `${rowHeights[cellRow]}px`;
        });
      };

      const tableSubColumns = table.querySelectorAll(".table_sub_column");
      const columnFilters = table.querySelectorAll(
        ".table__column-selecet--trigger"
      );
      const rowFilter = table.querySelector(".table__row-select");
      const tableCells = table.querySelectorAll(".table__cell");
      const subColumnCount = tableSubColumns.length;
      const cellCount = tableCells.length;

      const rowCount = cellCount / subColumnCount;

      rowTicker = 1;
      const handleCellRowCount = () => {
        tableCells.forEach((cell, i) => {
          if (rowTicker > rowCount) {
            rowTicker = 1;
          }

          if (rowTicker === 1) {
            cell.classList.add("row-visible");
          }

          cell.dataset.cellRow = rowTicker++;
        });
      };

      const layoutSelect = () => {
        const checkbox = table.querySelector(".table__layout-select");
        if (checkbox) {
          if (table.classList.contains("table__format--row")) {
            checkbox.checked = false;
          } else {
            checkbox.checked = true;
          }

          const select = (e) => {
            if (checkbox.checked) {
              checkbox.checked = false;
              handleLayoutSelect(e.target.checked);
            } else {
              checkbox.checked = true;
              handleLayoutSelect(e.target.checked);
            }
          };

          checkbox.addEventListener("keypress", (e) => {
            if (e.key === "Enter") {
              select(e);
            }
          });

          checkbox.addEventListener("click", (e) => {
            handleLayoutSelect(e.target.checked);
          });
        }
      };

      const handleLayoutSelect = (checked) => {
        if (checked) {
          table.classList.remove("table__format--row");
          table.classList.add(`table__format--column`);
        } else {
          table.classList.remove("table__format--column");
          table.classList.add(`table__format--row`);
        }

        handleCellHeight();
      };

      const populateRowSelect = () => {
        const dataColumn = table.querySelector(".table__data-column");

        if (dataColumn) {
          const rowLabels = dataColumn.querySelectorAll(".table__cell");

          rowLabels.forEach((label, i) => {
            const option = document.createElement("option");
            option.innerHTML = label.innerHTML;
            option.value = `${i + 1}`;
            rowFilter.appendChild(option);
          });
        }
      };

      const handleCellHeight = () => {
        const rowHeights = {}; // Store the maximum height for each cellRow

        tableCells.forEach((cell) => {
          const cellRow = cell.dataset.cellRow;
          const cellContent = cell.querySelector(".table__cell-content");

          if (
            !rowHeights[cellRow] ||
            cellContent.scrollHeight > rowHeights[cellRow]
          ) {
            rowHeights[cellRow] = cellContent.scrollHeight;
          }
        });

        tableCells.forEach((cell) => {
          const cellRow = cell.dataset.cellRow;
          cell.style.height = `${rowHeights[cellRow]}px`;
        });

        handleHeadHeight();
      };

      const columnSelectPill = () => {
        const activePill = table.querySelector(".column-filter-active");
        const columnPillTrigger = table.querySelector(
          ".table__column-selecet--trigger"
        );
        const columnPill = table.querySelector(
          ".table__column-select--highlight-pill"
        );
        columnPill.style.width = `${columnPillTrigger.clientWidth}px`;

        if (activePill) {
          columnPill.style.left = `${activePill.offsetLeft}px`;
        } else {
          columnPill.style.left = `0px`;
        }
      };

      const mobileColumnFilter = (button) => {
        const selectedColumn = button?.target?.dataset?.value
          ? button.target.dataset.value
          : "table__column--1";

        const triggerButtons = table.querySelectorAll(
          ".table__column-selecet--trigger"
        );
        triggerButtons.forEach((trigger, i) => {
          trigger.classList.remove("column-filter-active");
        });

        button?.target?.classList.add("column-filter-active");
        columnSelectPill(button);

        const columns = table.querySelectorAll(".table__column");
        columns.forEach((column) => {
          if (column.classList.contains(`${selectedColumn}`)) {
            column.classList.add("active-column");
          } else {
            column.classList.remove("active-column");
          }
        });
      };

      mobileColumnFilter();

      const handleMobileColumnFilter = () => {
        columnFilters.forEach((button) => {
          button.addEventListener("click", (button) => {
            mobileColumnFilter(button);
            handleCellHeight();
          });
        });

        columnFilters.forEach((button) => {
          button.addEventListener("keypress", (button) => {
            if (button.key === "Enter") {
              mobileColumnFilter(button);
              handleCellHeight();
            }
          });
        });
      };

      const handleMobileRowFilter = () => {
        const mobileRowFilter = () => {
          const selectedRow = rowFilter.value;
          const tableCells = table.querySelectorAll(".table__cell");

          tableCells.forEach((cell) => {
            if (cell.dataset.cellRow === selectedRow) {
              cell.classList.add("row-visible");
            } else {
              cell.classList.remove("row-visible");
            }
          });
        };

        rowFilter.addEventListener("change", () => {
          mobileRowFilter();
          handleCellHeight();
        });
      };

      columnSelectPill();
      populateRowSelect();
      // handleRowOrColumn();
      layoutSelect();
      handleMobileRowFilter();
      handleMobileColumnFilter();
      handleCellRowCount();
      handleCellHeight();

      window.addEventListener("resize", () => {
        handleHeadHeight();
        if (table.classList.contains("table__format--row")) {
          handleMobileRowFilter();
        } else {
          handleMobileColumnFilter();
        }
        handleCellHeight();
        columnSelectPill();
      });
    });
  }
}

if (document.readyState !== "loading") {
  handleTable();
} else {
  document.addEventListener("DOMContentLoaded", handleTable);
}
