if (document.readyState !== "loading") {
  initBodyScroll();
} else {
  document.addEventListener("DOMContentLoaded", initBodyScroll);
}

function initBodyScroll() {
  const { body } = document;

  const HEADER_STICKY = "header-sticky";

  // TODO: update name once settled
  const ONE_HUNDREDTH_OF_A_SECOND = 10;

  const checkScroll = () => {
    const currentScroll = window.scrollY;

    if (currentScroll > 20) {
      if (!body.classList.contains(HEADER_STICKY)) {
        body.classList.add(HEADER_STICKY);
      }
    } else {
      if (body.classList.contains(HEADER_STICKY)) {
        body.classList.remove(HEADER_STICKY);
      }
    }
  };

  const handleScroll = debounce(() => checkScroll(), ONE_HUNDREDTH_OF_A_SECOND);

  window.addEventListener("scroll", handleScroll);
  checkScroll();
}

// TODO: Remove if not needed
// add scroll direction class to body
let lastScrollTop = 0;

const scrollDirection = debounce(() => {
  let st = window.scrollY || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    document.body.classList.remove("scroll-up");
    document.body.classList.add("scroll-down");
  } else if (st < lastScrollTop) {
    document.body.classList.remove("scroll-down");
    document.body.classList.add("scroll-up");
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}, 500);

document.addEventListener("scroll", scrollDirection, false);
