document.addEventListener('DOMContentLoaded', function() {
  const dropdown = document.querySelector('.nzSnapHire_multiSelect-dropdown');
  const chevron = document.querySelector('.nzSnapHire_multiSelect-chevron');
  const checkboxes = document.querySelectorAll('.nzSnapHire_multiSelect-option input[type="checkbox"]');

  if (!dropdown || !chevron) {
    return;
  }

  // Close dropdown when clicking outside
  document.addEventListener('click', function(event) {
    if (!event.target.closest('.nzSnapHire_multiSelect')) {
      dropdown.classList.remove('show');
      chevron.classList.remove('up');
    }
  });
});

function toggleNzSnapHireDropdown() {
  const dropdown = document.querySelector('.nzSnapHire_multiSelect-dropdown');
  const chevron = document.querySelector('.nzSnapHire_multiSelect-chevron');
  dropdown.classList.toggle('show');
  chevron.classList.toggle('up');
}