if (document.readyState !== "loading") {
  initSearchToggle();
} else {
  document.addEventListener("DOMContentLoaded", initSearchToggle);
}

function initSearchToggle() {
  const searchOpenButton = document.querySelector(
    ".site-header__search-toggle"
  );

  if (searchOpenButton) {
    searchOpenButton.addEventListener("click", function () {
      const isActive = this.classList.contains("active");

      if (isActive && window.hasSearch.value) {
        window.hasSearch.value = false;
        return;
      }

      isActive ? hideMenu(this) : showMenu(this);
    });

    document.addEventListener("megaMenuClosed", function (e) {
      hideMenu(searchOpenButton);
    });
  }
}

function showMenu(item) {
  const parentNode = item.parentNode;
  const menu = parentNode.querySelector(".mega-menu__outer");
  const { body } = document;

  if (!menu) {
    return;
  }

  menu.classList.add("active");
  body.classList.add("mega-menu-active");
  body.classList.add("search-active");
  item.classList.add("active");
  disableScroll();
  setMenuAlignment(menu);
  focusSearch();

  const menusWrapper = document.querySelector(".mobile-menu__menus-wrapper");
  if (menusWrapper) {
    menusWrapper.classList.add("search");
  }

  const mobileMenu = document.querySelector(".mobile-menu__outer");
  if (mobileMenu) {
    mobileMenu.classList.add("active");
  }
}

function hideMenu(item) {
  const parentNode = item.parentNode;
  const menu = parentNode.querySelector(".mega-menu__outer");
  const { body } = document;
  const shouldMenuActive = !!document.querySelector(
    ".site-header__menu-button.active"
  );

  if (!menu) {
    return;
  }

  menu.classList.remove("active");
  !shouldMenuActive && body.classList.remove("mega-menu-active");
  body.classList.remove("search-active");
  item.classList.remove("active");
  removeMenuAlignment();
  enableScroll();

  const menusWrapper = document.querySelector(".mobile-menu__menus-wrapper");
  if (menusWrapper) {
    menusWrapper.classList.remove("search");
  }

  const mobileMenu = document.querySelector(".mobile-menu__outer");
  const mobileMenuActive = !!document.querySelector("body.mobile-menu-active");
  if (mobileMenu && !mobileMenuActive) {
    mobileMenu.classList.remove("active");
  }
}

function focusSearch() {
  const searchInputs = document.querySelectorAll(".search-workflow__input");

  if (searchInputs) {
    searchInputs.forEach((searchInput) => {
      if (searchInput.offsetParent !== null) {
        setTimeout(() => {
          searchInput.focus();
        }, 300);
      }
    });
  }
}
