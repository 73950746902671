function controlElementHeight() {
  const imageLeftRightWrapper = document.querySelectorAll(
    ".left-right-image-copy__wrapper"
  );
  
  const imageLeftRightWrapperResize = debounce(() => {
    imageLeftRightWrapper.forEach((wrapperElement) => {
      const textContent = wrapperElement.querySelector(
        ".left-right-image-copy__text-col"
        );
        const imageContent = wrapperElement.querySelector(
          ".left-right-image-copy__image-col"
        );
      imageContent.style.maxHeight = `${textContent.clientHeight}px`;
    });
  }, 500);

  imageLeftRightWrapperResize();

  window.addEventListener("resize", imageLeftRightWrapperResize);
}

if (document.readyState !== "loading") {
  controlElementHeight();
} else {
  document.addEventListener("DOMContentLoaded", controlElementHeight);
}
