function addFirefoxCompatibilityClassToVideo() {
  const figureCollection = document.getElementsByTagName("figure");
  const figureArray = Array.from(figureCollection);

  figureArray.forEach((figure) => {
    if (figure.firstElementChild.localName === "iframe") {
      figure.classList.add("matrix-wysiwyg__video");
    }
  });
}

if (navigator.userAgent.indexOf("Firefox") > 0) {
  if (document.readyState !== "loading") {
    addFirefoxCompatibilityClassToVideo();
  } else {
    document.addEventListener(
      "DOMContentLoaded",
      addFirefoxCompatibilityClassToVideo
    );
  }
}
