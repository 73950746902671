if (document.readyState !== "loading") {
  initMegaMenuHandler();
} else {
  document.addEventListener("DOMContentLoaded", initMegaMenuHandler);
}

function initMegaMenuHandler() {
  const menuItems = document.querySelectorAll(
    ".site-header__nav-link.has-menu, .site-header__menu-button"
  );
  const { body } = document;

  if (menuItems) {
    menuItems.forEach((menuItem) => {
      menuItem.addEventListener("click", function (e) {
        const parentNode = this.parentNode;
        const menu = parentNode.querySelector(".mega-menu__outer");

        if (!menu) return;

        if (menu.classList.contains("active")) {
          menu.classList.remove("active");
          body.classList.remove("mega-menu-active");
          body.classList.remove("workflow-active");
          menuItem.classList.remove("active");
          removeMenuAlignment();
          return;
        }

        // TODO: move into func
        const activeMenus = document.querySelectorAll(
          ".mega-menu__outer.active"
        );
        if (activeMenus.length) {
          activeMenus.forEach((activeMenu) => {
            activeMenu.classList.remove("active");
            activeMenu.classList.remove("skew-left");
            activeMenu.classList.remove("skew-right");

            const activeMenuItems = document.querySelectorAll(
              ".site-header__nav-link.active, .site-header__menu-button.active"
            );

            if (activeMenuItems) {
              activeMenuItems.forEach((activeMenuItem) => {
                activeMenuItem.classList.remove("active");
              });
            }
          });
        }

        menu.classList.add("active");
        menuItem.classList.add("active");
        body.classList.add("mega-menu-active");

        if (e.target.classList.contains("site-header__menu-button")) {
          body.classList.add("workflow-active");
        } else {
          body.classList.remove("workflow-active");
        }

        document.documentElement.style.setProperty(
          "--mega-menu-height",
          `${menu.clientHeight}px`
        );

        setMenuAlignment(menu);
      });
    });
  }

  const closeButton = document.querySelector(".mega-menu__close-button");
  if (closeButton) {
    closeButton.addEventListener("click", function (e) {
      const megaMenu = document.querySelector(".mega-menu__outer.active");
      if (megaMenu) {
        megaMenu.classList.remove("active");
        const navItem =
          document.querySelector(".site-header__nav-link.active") ||
          document.querySelector(".site-header__menu-button.active");
        if (navItem) {
          navItem.classList.remove("active");
        }
        body.classList.remove("mega-menu-active");
      }

      const mobileMenu = document.querySelector(".mobile-menu__outer.active");
      if (mobileMenu) {
        mobileMenu.classList.remove("active");
        const navItem = document.querySelector(
          ".site-header__mobile-menu.active"
        );
        enableScroll();
        if (navItem) {
          navItem.classList.remove("active");
        }
        body.classList.remove("mobile-menu-active");
        body.classList.remove("mobile-workflow-active");

        const menusWrapper = document.querySelector(
          ".mobile-menu__menus-wrapper"
        );
        menusWrapper.classList.remove("search");
      }

      body.classList.remove("search-active");

      removeMenuAlignment();

      document.dispatchEvent(new Event("megaMenuClosed"));
    });
  }
}

// set the alignment of the mega menu based on the index relative to the active menu
function setMenuAlignment(activeMenu) {
  const activeMenuIndex = activeMenu.dataset.index;
  activeMenu.classList.remove("skew-right");
  activeMenu.classList.remove("skew-left");

  const menus = document.querySelectorAll(".mega-menu__outer");

  if (menus) {
    menus.forEach(function (menu) {
      const currentMenuIndex = menu.dataset.index;

      if (currentMenuIndex === activeMenuIndex) {
        return;
      } else if (currentMenuIndex > activeMenuIndex) {
        menu.classList.add("skew-right");
        menu.classList.remove("skew-left");
      } else {
        menu.classList.add("skew-left");
        menu.classList.remove("skew-right");
      }
    });
  }
}

// Remove active class names from all mega menusF
function removeMenuAlignment() {
  const menus = document.querySelectorAll(".mega-menu__outer[class*='skew']");
  if (menus) {
    menus.forEach(function (menu) {
      menu.classList.remove("skew-left");
      menu.classList.remove("skew-right");
    });
  }
}
