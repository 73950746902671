function embedVideoHideShowOverlay() {
  const videoCollection = document.getElementsByClassName("embed-video__wrapper");

  if (videoCollection) {
    // Preemptively loads the YouTube Iframe API even if there are no YT embeds
    ensureYoutubeAPIReady()

    const videoArray = Array.from(videoCollection);

    videoArray.forEach((video, i) => {
  
      const frame = video.getElementsByTagName("iframe")
      
      frame[0].onload = function() {
        
        frame[0].style.pointerEvents = "none"
        video.addEventListener('click', () => {
          frame[0].style.pointerEvents = "auto"
          const placeholder = video.querySelector(".video__placeholder-wrapper")
          const overlay = video.querySelector(".video__overlay")
          placeholder.style.opacity = 0
          overlay.style.opacity = 0

          ensureYoutubeAPIReady().then(() => {
            const player = new YT.Player(frame[0], {
              events: {
                'onReady': () => {
                  player.playVideo()
                },
              },
            })
          })
        })
      }
    });
  }
}

function ensureYoutubeAPIReady() {
  return new Promise((resolve, reject) => {
    if (window.YT && window.YT.Player) {
      return resolve();
    }

    window.onYouTubeIframeAPIReady = () => {
      resolve()
    }

    var script = document.createElement("script")
    script.id = "youtube-iframe-script"
    script.src = "https://www.youtube.com/iframe_api"
    var firstScriptTag = document.getElementsByTagName("script")[0]
    firstScriptTag.parentNode.insertBefore(script, firstScriptTag)
  })
}

if (document.readyState !== "loading") {
  embedVideoHideShowOverlay();
} else {
  document.addEventListener("DOMContentLoaded", embedVideoHideShowOverlay);
}
