if (document.readyState !== "loading") {
  resizeIframes();
} else {
  document.addEventListener("DOMContentLoaded", resizeIframes);
}

function resizeIframes() {
  const dynamicHeightFrame = document.querySelector(".dynamic-height iframe");

  if (dynamicHeightFrame) {
    window.addEventListener(
      "message",
      function (message) {
        // * use telfords?
        if (message.origin.includes("kinetic")) {
          // the response from the coach manager embed has an array of data with the height as the second item
          const height = message.data[1];

          if (!isNaN(height)) {
            dynamicHeightFrame.style.height = height + "px";
          }
        }
      },
      false
    );
  }

  const resizeHeightFrames = document.querySelector("iframe");
  if (resizeHeightFrames) {
    iFrameResize({}, resizeHeightFrames);
  }
}
