function toggleStickyNav(e) {
  const parent = e.target.parentNode;
  const greatGrandparent = e.target.parentNode.parentNode.parentNode;
  const buttonGroup = parent.querySelector(
    ".location-hero__sticky-navigation--button-group"
  );
  const buttonGrid = buttonGroup.querySelector(
    ".location-hero__navigation-grid"
  );

  if (
    greatGrandparent.classList.contains(
      "location-hero__sticky-navigation--open"
    )
  ) {
    greatGrandparent.classList.remove("location-hero__sticky-navigation--open");
    buttonGroup.style.maxHeight = "0";
  } else {
    greatGrandparent.classList.add("location-hero__sticky-navigation--open");
    buttonGroup.style.maxHeight = `${buttonGrid.scrollHeight}px`;
  }
}
const locationHeroSubNav = document.querySelector(
  ".location-hero__sticky-navigation--wrapper"
);


function initLocationHeroObserver() {
  const locationHero = document.querySelector(".location-hero__wrapper");
  
  const showSubNav = debounce((entry) => {
    if (entry.isIntersecting) {
      locationHeroSubNav.classList.remove(
        "location-hero__sticky-navigation--active"
      );
    } else {
      locationHeroSubNav.classList.add(
        "location-hero__sticky-navigation--active"
      );
    }
  }, 200);

  let locationHeroObserver = new IntersectionObserver(
    function (entries) {
      entries.forEach((entry) => {
        showSubNav(entry);
      });
    },
    { root: null }
  );

  if(locationHero) {
    locationHeroObserver.observe(locationHero);
  }
}

if (document.readyState !== "loading") {
  initLocationHeroObserver();
} else {
  document.addEventListener("DOMContentLoaded", initLocationHeroObserver);
}
