function initialiseOembed() {
  document.querySelectorAll("oembed[url]").forEach((element) => {
    const url = element.getAttribute("url");
    let iframeSrc = "";

    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = extractYouTubeId(url);
      iframeSrc = `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes("vimeo.com")) {
      const videoId = extractVimeoId(url);
      iframeSrc = `https://player.vimeo.com/video/${videoId}`;
    }

    if (iframeSrc) {
      const iframe = document.createElement("iframe");
      iframe.setAttribute("src", iframeSrc);
      iframe.setAttribute("frameborder", "0");
      iframe.setAttribute("allowfullscreen", "true");

      element.appendChild(iframe);
    }
  });
}

// Helper functions to extract video IDs
function extractYouTubeId(url) {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

function extractVimeoId(url) {
  const regExp =
    /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/;
  const match = url.match(regExp);
  return match ? match[1] : null;
}

if (document.readyState !== "loading") {
  initialiseOembed();
} else {
  document.addEventListener("DOMContentLoaded", initialiseOembed);
}
