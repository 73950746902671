if (document.readyState !== "loading") {
  initializeBusRoutes();
} else {
  document.addEventListener("DOMContentLoaded", initializeBusRoutes);
}

function updateTabThumb(button = null) {
  const tabThumb = document.querySelector(".routes__selected-tab-thumb");
  const currentTab = button
    ? button
    : document.querySelector(".routes-time-selector.active");

  if (tabThumb && currentTab) {
    tabThumb.style.left = `${currentTab.offsetLeft}px`;
    tabThumb.style.width = `${currentTab.offsetWidth}px`;
  }
}

function initializeBusRoutes() {
  const timeButtons = document.querySelectorAll(".routes-time-selector");
  updateTabThumb();

  if (timeButtons) {
    timeButtons.forEach((button) => {
      button.addEventListener("click", () => {
        if (button.classList.contains("active")) return;

        const timeOfDay = button.dataset.timeOfDay;

        const routes = document.querySelector(
          `.route__routes[data-time-of-day="${timeOfDay}"]`
        );

        const currentActiveRoutes = document.querySelector(
          ".route__routes.active"
        );
        const currentActiveButton = document.querySelector(
          ".routes-time-selector.active"
        );

        if (routes) {
          button.classList.add("active");
          routes.classList.add("active");
          updateTabThumb(button);
          currentActiveRoutes.classList.remove("active");
          currentActiveButton.classList.remove("active");
        }
      });
    });
  }
}
