if (document.readyState !== "loading") {
  initCarousel();
} else {
  document.addEventListener("DOMContentLoaded", initCarousel);
}

function initCarousel() {
  const swiper = new Swiper(".newsroom-carousel__inner", {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: ".newsroom-carousel__nav-btn-next",
      prevEl: ".newsroom-carousel__nav-btn-prev",
    },
    scrollbar: {
      el: ".newsroom-carousel__scrollbar",
      clickable: true,
    },
    breakpoints: {
      1025: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  });
}
