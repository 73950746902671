function handleTabThumb(tabWrapper) {
  const tabThumb = tabWrapper.querySelector(
    ".tabbed-content__selected-tab-thumb"
  );
  const currentTab = tabWrapper.querySelector(
    ".tabbed-content__swap-button-active"
  );

  if (tabThumb && currentTab) {
    tabThumb.style.left = `${currentTab.offsetLeft}px`;
    tabThumb.style.width = `${currentTab.offsetWidth}px`;
  }
}

function swapTab(e) {
  const tabIndex = e.target.classList[1];
  const tabWrapper = e.target.parentNode.parentNode.parentNode.parentNode;
  const tabButtonsHTMLCollection = e.target.parentNode.parentNode.children;
  const tabButtonsArray = [...tabButtonsHTMLCollection];
  const tabContent = tabWrapper.querySelector(
    ".tabbed-content__content-container"
  );

  tabButtonsArray.forEach((button) => {
    if (button.classList.contains(`${tabIndex}`)) {
      button.classList.add("tabbed-content__swap-button-active");
    } else {
      button.classList.remove("tabbed-content__swap-button-active");
    }
  });

  tabContent.classList.add("tab__transitioning");
  // clearTimeout(hideSlidingContent, 600);

  function hideSlidingContent() {
    tabContent.classList.remove("tab__transitioning");
  }

  setTimeout(hideSlidingContent, 500);

  getTabs(tabIndex, tabWrapper);

  // move tab thumb
  handleTabThumb(tabWrapper);
}

function displayContent(tabItem, currentTab) {
  tabItem.forEach((content) => {
    const tabIndex = content.classList[1];
    let tab = currentTab ? currentTab : "tab-group-1";

    if (tab === tabIndex) {
      content.classList.add("active-tab");
      content.style.opacity = 1;
      content.style.pointerEvents = "auto";

      // test
      tabItem.forEach((item) => {
        const itemPosition = [...item.classList[1]].pop();
        const currentTabPosition = [...currentTab].pop();

        if (item.classList.contains("tabbed-content__tab-content-group")) {
          if (Number(itemPosition) > Number(currentTabPosition)) {
            item.style.transform = "translateX(105%)";
          } else if (Number(itemPosition) < Number(currentTabPosition)) {
            item.style.transform = "translateX(-105%)";
          } else {
            item.style.transform = "translateX(-0%)";
          }
        }
      });
    } else {
      content.classList.remove("active-tab");
      content.style.opacity = 0;
      content.style.pointerEvents = "none";
    }
  });
}

function getTabs(currentTab, tabWrapper) {
  const tabContent = tabWrapper.querySelectorAll(
    ".tabbed-content__tab-content-group"
  );
  const tabImage = tabWrapper.querySelectorAll(
    ".tabbed-content__image-viewport"
  );
  displayContent(tabContent, currentTab);
  displayContent(tabImage, currentTab);

  let tabHeight;
  tabContent.forEach((content) => {
    if (content.classList[1] === currentTab) {
      const scrollHeight = content.querySelector(
        ".tabbed-content__tab-content-height"
      );
      tabHeight = `${scrollHeight.scrollHeight}px`;
    }
  });
  const contentHeight = tabWrapper.querySelector(
    ".tabbed-content__tab-content-wrapper"
  );
  contentHeight.style.height = tabHeight;
}

function initializeTabs() {
  const tabBlocks = document.querySelectorAll(".tabbed-content__wrapper");

  const tabButtons = document.querySelectorAll(
    ".tabbed-content__swap-button-title"
  );

  tabButtons.forEach((button) => {
    button.addEventListener("keydown", function (e) {
      if (e.code == "Space" || e.code == "Enter") {
        document.activeElement.click();
      }
    });
  });

  tabBlocks.forEach((block) => {
    const swapTabButtons = block.querySelector(".tabbed-content__button-group");
    const tabContent = block.querySelectorAll(
      ".tabbed-content__tab-content-group"
    );
    const tabImage = block.querySelectorAll(".tabbed-content__image-viewport");
    swapTabButtons.children[0].classList.add(
      "tabbed-content__swap-button-active"
    );
    displayContent(tabContent, "tab-group-1");
    displayContent(tabImage, "tab-group-1");

    // initialize tab thumb
    handleTabThumb(block);

    // set starting tab height
    const defaultTab = block.querySelector(
      ".tabbed-content__tab-content-group"
    );
    const defaultTabContent = block.querySelector(
      ".tabbed-content__tab-content-wrapper"
    );

    defaultTabContent.style.height = `${defaultTab.scrollHeight}px`;
  });

  function resizeEvent() {
    let tabHeight;
    const tabWrappers = document.querySelectorAll(".tabbed-content__wrapper");
    const tabContent = document.querySelectorAll(
      ".tabbed-content__tab-content-group"
    );

    tabContent.forEach((content) => {
      if (content.classList.contains("active-tab")) {
        const scrollHeight = content.querySelector(
          ".tabbed-content__tab-content-height"
        );
        const tabWrapper = content.parentNode;
        tabHeight = `${scrollHeight.scrollHeight}px`;
        tabWrapper.style.height = tabHeight;
      }
    });

    // adust tab thumbs on resize
    tabWrappers.forEach((tabWrapper) => {
      handleTabThumb(tabWrapper);
    });
  }

  window.addEventListener("resize", resizeEvent);
}

if (document.readyState !== "loading") {
  initializeTabs();
} else {
  document.addEventListener("DOMContentLoaded", initializeTabs);
}
