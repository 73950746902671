function getSliderHeight() {
  const cardSlider = document.querySelectorAll(".cards__with-mobile-slider");
  const sliderArray = [...cardSlider];

  // set slider height
  if (sliderArray.length) {
    sliderArray.forEach((sliderWrapper) => {
      const slider = sliderWrapper.querySelector(".cards__mobile-slider");
      slider.nextElementSibling.style.height = `${slider.scrollHeight}px`;
      const resizeEvent = debounce(() => {
        slider.nextElementSibling.style.height = `${slider.scrollHeight}px`;
      }, 1000);
      window.addEventListener("resize", resizeEvent);
    });
  }

  sliderArray.forEach((sliderWrapper) => {
    const slider = sliderWrapper.querySelector(".cards__mobile-slider");
    const slides = slider.querySelectorAll(".card__wrapper");
    const scrollbarContainer = sliderWrapper.querySelector(
      ".cards__scrollbar-container"
    );
    const scrollbarPortions = sliderWrapper.querySelectorAll(
      ".cards__scrollbar-portion"
    );
    const thumb = sliderWrapper.querySelector(".cards__scrollbar-thumb");

    thumb.style.width = `${scrollbarPortions[0].clientWidth}px`;

    function closest(number, array) {
      return array.reduce((a, b) => {
        let aDiff = Math.abs(a - number);
        let bDiff = Math.abs(b - number);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }

    // drag scroll
    let grabbed = false;
    let startX;
    let scrollLeft;
    let positionArray = [];
    let closestNumber = 0;

    let totalWidth = 0;

    let percentageWidth = 0;

    slides.forEach((slide) => {
      positionArray = [...positionArray, slide.offsetLeft];
      totalWidth = totalWidth + slide.clientWidth;
      percentageWidth = (scrollbarContainer.clientWidth / totalWidth) * 100;
    });

    function triggerSlide(slide) {
      if (slide.offsetLeft === closest(slider.scrollLeft, positionArray)) {
        slide.classList.add("activeSlide");
        closestNumber = slide.offsetLeft;
      } else {
        slide.classList.remove("activeSlide");
      }

      thumb.style.left = `${(closestNumber / 100) * percentageWidth}px`;
      slider.scrollTo({
        left: Number(closestNumber),
        behavior: "smooth",
      });
    }

    window.addEventListener("resize", () => {
      positionArray = [];
      totalWidth = 0;
      thumb.style.width = `${scrollbarPortions[0].clientWidth}px`;
      slides.forEach((slide) => {
        positionArray = [...positionArray, slide.offsetLeft];
        totalWidth = totalWidth + slide.clientWidth;
        percentageWidth = (scrollbarContainer.clientWidth / totalWidth) * 100;
        triggerSlide(slide);
      });
    });

    slider.addEventListener("mousedown", (e) => {
      grabbed = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });

    slider.addEventListener("mouseleave", (e) => {
      grabbed = false;
      slider.classList.remove("active");
      slides.forEach((slide) => {
        triggerSlide(slide);
      });
    });

    slider.addEventListener("mouseup", (e) => {
      grabbed = false;
      slider.classList.remove("active");
      e.preventDefault();

      slides.forEach((slide) => {
        triggerSlide(slide);
      });
    });

    slider.addEventListener("mousemove", (e) => {
      slider.classList.remove("touchDevice");
      if (!grabbed) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = x - startX;
      slider.scrollLeft = scrollLeft - walk;

      // move scroll thumb
      if (((scrollLeft - walk) / 100) * percentageWidth > 0 && ((scrollLeft - walk) / 100) * percentageWidth < (scrollbarContainer.clientWidth - scrollbarPortions[0].clientWidth)) {
        thumb.style.left = `${((scrollLeft - walk) / 100) * percentageWidth}px`;

      }
    });

    // touch
    slider.addEventListener("touchstart", (e) => {
      slider.classList.add("touchDevice")
    });

    slider.addEventListener("scroll", (e) => {
      if(slider.classList.contains("touchDevice")) {
        slides.forEach((slide) => {
          if (slide.offsetLeft === closest(slider.scrollLeft, positionArray)) {
            slide.classList.add("activeSlide");
            closestNumber = slide.offsetLeft;
  
            thumb.style.left = `${((closestNumber) / 100) * percentageWidth}px`;
  
          } else {
            slide.classList.remove("activeSlide");
          }
        });
      }
    });
  });
}

if (document.readyState !== "loading") {
  getSliderHeight();
} else {
  document.addEventListener("DOMContentLoaded", getSliderHeight);
}
